import React from "react";
import Layout from "../../components/layout";
import MathijsEnNoortje from '../../components/portfolio/mathijsEnNoortje.component';


export default function MathijsEnNoortjeGalleryPage(props){

  return (
    <Layout>
    <MathijsEnNoortje/>
  </Layout>
  )
}



